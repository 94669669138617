app-btn-primary {
  button {
    color: #ffffff;
    background-color: #07254a;
    box-shadow: 0px 1px 2px 0px #1018280d;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    height: 44px;
    @media (width<=$size-to-switch-to-the-mobile-version) {
      height: 36px;
    }
  }
}

app-btn-primary.btn-white {
  button {
    background-color: #ffffff;
    box-shadow: 0px 1px 2px 0px #1018280d;
    border: 1px solid #d0d5dd;
    color: #07254a;
  }
}

@import 'theme/variables';

$scale: 0.7;

//view-is-desktop
@media (#{$small-web-screens}) {
  .zoom-content {
    transform-origin: top center;
    transform: scale(#{$scale});
    margin-left: -21%;
    margin-right: -21%;
  }

  .cdk-overlay-pane {
    &:has(.mat-mdc-menu-panel) {
      transform-origin: left top;
    }

    &.mat-datepicker-popup {
      transform: scale(.7) !important;
      transform-origin: top left;
      margin-top: 6px;
    }

    .mat-mdc-menu-panel.mdc-menu-surface--open {
      transform: scale(#{$scale}) !important;
    }

    .mat-mdc-dialog-container {
      transform: scale(#{$scale}) !important;
    }

    &.forOverlayPanelDropdown, &.forOverlayPanelDropFormCtrl {
      transform: scale(#{$scale});
      transform-origin: top left;
      margin-top: 6px;
    }
  }

  .mdc-tooltip {
    &__surface {
      transform-origin: top center !important;
      transform: scale(#{$scale}) !important;
    }
  }


  // #cdk-overlay-2 .cdk-overlay-pane {
  //     @include widthImportant(100%);
  // }

}

//desktop-big
@media (#{$big-web-screens}) {
  .zoom-content {
    transform-origin: top;
    transform: scale(1);
  }
}
competitionsofficiallabels {
  inputctrl {
    .o-matForm .mat-form-field-flex, .o-matForm .mat-mdc-text-field-wrapper {
      width: auto;
      min-width: auto;
      max-width: none;
      height: auto;
      min-height: auto;
      max-height: none;
    }

    .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
      display: flex;
      align-items: center;
      padding: 0;
    }
  }
}
@import 'theme/variables';

@mixin text-overflow($width) {
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-0($color) {
  font-family: $main-font;
  font-weight: $main-font-weight-600;
  font-style: normal;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: $color;

  @media (max-width: $size-to-switch-to-the-mobile-version) {
    font-family: $main-font;
    font-weight: $main-font-weight-700; // !!! NOT30-371 Official → My Wallet $105.00 - should be 40pt bold
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.4000000059604645px;
    //text-align: center;
  }
}

// 28 - 36 - 700
@mixin text-1($color) {
  font-family: $main-font;
  font-weight: $main-font-weight-700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.4px;
  color: $color;
}

// 24 - 32 - 600
@mixin text-2($color) {
  font-family: $main-font;
  font-weight: $main-font-weight-600;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  color: $color;
}

// 18 - 24 - 700
@mixin text-3($color) {
  font-family: $main-font;
  font-weight: $main-font-weight-700;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: $color;
}

// 20 - 32 - 600
@mixin text-new-3($color) {
  font-family: $main-font;
  font-weight: $main-font-weight-600;
  font-style: normal;
  font-size: 20px;
  line-height: 32px;
  color: $color;
}

// 18 - 28 - 600
@mixin text-4($color) {
  font-family: $main-font;
  font-weight: $main-font-weight-600;
  font-style: normal;
  font-size: 18px;
  line-height: 28px;
  color: $color;
}

// 16 - 24 - 600
@mixin text-5($color) {
  font-family: $main-font;
  font-weight: $main-font-weight-600;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: $color;
}

// 16 - 24 - 400
@mixin text-6($color) {
  font-family: $main-font;
  font-weight: $main-font-weight-400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: $color;
}

// 14 - 24 - 600
@mixin text-7($color) {
  font-family: $main-font;
  font-weight: $main-font-weight-600;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: $color;
}

// 14 - 24 - 400
@mixin text-8($color) {
  font-family: $main-font;
  font-weight: $main-font-weight-400;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: $color;
}

// 12 - 20 - 600
@mixin text-9($color) {
  font-family: $main-font;
  font-weight: $main-font-weight-600;
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  color: $color;
}

// 12 - 18 - 400
@mixin text-10($color) {
  font-family: $main-font;
  font-weight: $main-font-weight-400;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: $color;
}

// 18 - 28 - 400
@mixin text-11($color) {
  font-family: $main-font;
  font-weight: $main-font-weight-600;
  font-style: normal;
  font-size: 18px;
  line-height: 28px;
  color: $color;
}

// WIDTH & HEIGHT
@mixin width($width, $auto: false) {
  @if $auto {
    width: auto;
    min-width: auto;
    max-width: none;
  } @else {
    width: $width;
    min-width: $width;
    max-width: $width;
  }
}

@mixin widthImportant($width) {
  width: $width !important;
  min-width: $width !important;
  max-width: $width !important;
}

@mixin height($height, $auto: false) {
  @if $auto {
    height: auto;
    min-height: auto;
    max-height: none;
  } @else {
    height: $height;
    min-height: $height;
    max-height: $height;
  }
}

@mixin heightImportant($height) {
  height: $height !important;
  min-height: $height !important;
  max-height: $height !important;
}

@mixin widthHeight($value) {
  width: $value;
  min-width: $value;
  max-width: $value;
  height: $value;
  min-height: $value;
  max-height: $value;
}

@mixin widthHeightImportant($value) {
  width: $value !important;
  min-width: $value !important;
  max-width: $value !important;
  height: $value !important;
  min-height: $value !important;
  max-height: $value !important;
}

@mixin widthField() {
  @include width(calc(50% - 10px));
}

// FLEX
@mixin flex {
  display: flex;
  align-items: center;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexColumn {
  display: flex;
  flex-direction: column;
}

@mixin text7Btn($color) {
  @include text-7($color);
}

@mixin text5Btn($color) {
  @include text-5($color);
}

@mixin textDropdown($color) {
  //@include text-6($color);
  @include text-7($color); //https://notch.atlassian.net/browse/NOT30-57
}

// state input ========================
@mixin textInput($color) {
  @include text-6($color);
}

@mixin fieldEmpty($forInput: boolean) {
  border: 1px solid $grey_2; // $colorPlaceholder

  @if $forInput {
    @include textInput($colorPlaceholder); // $colorPlaceholder
  } @else {
    @include textDropdown($colorPlaceholder); // $colorPlaceholder
  }
}

@mixin fieldFilled($forInput: boolean) {
  border: 1px solid $grey_2;

  @if $forInput {
    @include textInput($blueDark);
  } @else {
    @include textDropdown($blueDark);
  }
}

@mixin fieldActive($forInput: boolean) {
  border: 1px solid $blueDark;
  box-shadow: 0 1px 2px rgba(100, 233, 134, 0.2), 0 0 0 4px rgba(100, 233, 134, 0.2);

  @if $forInput {
    @include textInput($blueDark);
  } @else {
    @include textDropdown($blueDark);
  }
}

@mixin fieldDisabled($forInput: boolean) {
  border: 1px solid $grey_2;
  // !!! https://notch.atlassian.net/browse/NOT30-138 Моника сказала поменять для Disabled
  background-color: $grey_4 !important; // было $grey_3==#EAECF0

  @if $forInput {
    @include textInput($grey_2);
  } @else {
    @include textDropdown($grey_2);
  }
}

@mixin fieldError($forInput: boolean) {
  border: 1px solid $red;

  @if $forInput {
    @include textInput($red);
  } @else {
    @include textDropdown($red);
  }
}

@mixin enlargeBcgImg($linkSvg) {
  background-image: url($linkSvg);
  background-size: $enlargeSize;
  //display: inline-block;
  display: inline-block !important;
  visibility: visible !important;
  width: $enlargeSize;
  height: $enlargeSize;
  content: '';
}

//=== MAT OPTION ===========================
@mixin matOption {
  @include text-7($blueDark);
  @include height($height-btnInput);
  padding: 0 $paddingForDrop !important;
  border-radius: 6px;
}

// !!! элемент внутри выпадающего списка #menu='matMenu'
@mixin matMenuItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include text-7($blueDark); // https://notch.atlassian.net/browse/NOT30-57
  height: $height-btnInput;
  padding: 0 10px;
  text-decoration: none;
}

//=== TABLE ===================================
@mixin textTableHeader {
  @include text-7($blueDark);
}

// === transform scale ===============
@mixin transform_scale($transform_origin) {
  transform-origin: $transform_origin;
  -moz-transform: scale(0.8);
  /* Firefox */
  -webkit-transform: scale(0.8);
  /* Chrome, Safari, Opera */
  transform: scale(0.8);
  /* Standard syntax */
}

//=== OTHER =============================
@mixin disabled() {
  opacity: 0.5;
  cursor: initial !important;
}

@mixin tooltipDefault() {
  @include text-9($white);
  padding: 8px;
  border-radius: 8px;
  background: $blueDark;
}

@mixin arrowDownForTooltip {
  content: '';
  position: absolute;
  bottom: -11px;
  left: calc(50% - 6px);
  border-width: 6px;
  border-style: solid;
  border-color: $blueDark transparent transparent transparent;
}

@mixin gameDetailsHeadTitle {
  @include height(56px);
  @include text-3($blueDark);
  @include flex;
  padding-bottom: 16px;

  // border-bottom: 1px solid $grey_2;
  @media (max-width: $size-to-switch-to-the-mobile-version) {
    @include height(initial);
    @include text-5($blueDark);
    padding-bottom: 0;
    border-bottom: none;
  }
}

@import 'theme/variables';
@import 'theme/mixins';

:host {
  display: block;
}

@mixin text-default-forTable {
  @include text-8($blueDark);
}

@mixin text-bold-forTable {
  @include text-7($blueDark);
  color: inherit !important; // !!! NO DELETE. Без этого не работает изменение цвета (gameCanceled) на странице games
}

@mixin textGameNumber-forTable {
  @include text-5($blueDark);
  color: inherit !important; // !!! NO DELETE. Без этого не работает изменение цвета (gameCanceled) на странице games
}

.t-wrapperTable {
  margin-top: 24px;
  border: 1px solid $grey_2;
  border-radius: 8px;
  box-shadow: 0 4px 35px 0 #0010380F;
}

.t-header {
  @include height(44px);
  @include text-bold-forTable;
  color: $blueDark !important;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $grey_3;
  border-radius: 8px 8px 0 0;
  background-color: $grey_4;

  &__ceil {
    display: flex;
    align-items: center;

    &__chx {
      margin-right: 12px;
      cursor: pointer;
    }

    &__arrow {
      margin-left: 4px;
    }

    //&__text2 {
    //  margin-left: auto;
    //}
    & sortBy {
      margin-left: auto;
    }
  }

  &__ceil:last-child {
    justify-content: center;
  }
}

.t-table {
  background-color: $white;
  border-radius: 0 0 8px 8px;

  &__item {
    position: relative;
    @include flex;
    @include text-default-forTable;
    border-bottom: 1px solid $grey_3;

    &__wrapCeil {
      @include flex;

      &__ceilRow {
        @include flex;

        &__chx {
          margin-right: 12px;
          cursor: pointer;
        }
      }

      &__ceilColumn {
        @include text-overflow(100%);
        @include flexColumn;
        @include text-overflow(max-content);

        &__line1, &__line2 {
          max-width: max-content;
        }
      }

      &__actions {
        display: flex !important;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}

//=== ACTIONS FOR ITEM TABLE (троеточия) ============================
.t-actionsItemTable {
  @include flexCenter;
}

.t-table__item__game-number {
  font-weight: 600;
  font-size: 16px;
  color: $blueDark;
}

// !!! for pages openGames & assign (для этих таблиц дизайн отличается
.t-tableVersion2 {
  & .t-table__item {
    align-items: flex-start;
    padding: 16px 0;
  }


  & .wrapperTableDoubleCeil {
    @include flexColumn;

    &__wrapCeil:last-child {
      margin-top: 24px;
    }
  }
}

// additional styles for table
.t {
  &-fontBold {
    @include text-bold-forTable;
  }

  &-textGameNumber-forTable {
    @include textGameNumber-forTable;
  }

  &-isCentered {
    justify-content: center;
    text-align: center;
  }
}


// FOR MOBILE ================================================
@media (max-width: $size-to-switch-to-the-mobile-version) {
  app-tableCeil, app-tableDoubleCeil, app-tableCeilUser, app-tableCeil-assignor {
    width: 100%;
  }

  .t-wrapperTable {
    box-shadow: none;
    border: none;
  }

  .t-table {

    &__item {
      flex-direction: column;
      margin-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $grey_2;

      &__wrapCeil {
        position: relative;
        height: 58px;
        @include flexColumn;
        align-items: flex-start;
        justify-content: center;

        &__titleFromHeader {
          @include text-bold-forTable;
        }

        &__ceilRow {
          &__chx {}
        }

        &__ceilColumn {
          //position: relative;
          @include width(100%);
          flex-direction: row;

          &__line1, &__line2 {}
        }

        &__actions {}

        &__svgRightForMobile {
          position: absolute;
          right: 0;
        }
      }
    }

    &__item:first-child {
      margin-top: 0;
    }
  }

  //=== ACTIONS FOR ITEM TABLE (троеточия) ============================
  .t-actionsItemTable {
    @include flexColumn;
    @include width(100%);

    & button {
      @include height(36px);
      margin-top: 12px;
    }
  }

  // !!! for pages openGames & assign (для этих таблиц дизайн отличается
  .t-tableVersion2 {
    background: $grey_4;

    & .wrapperTableDoubleCeil {
      &__wrapCeil {
        @include height(48px); // https://notch.atlassian.net/browse/NOT30-688
        //margin-top: 0 !important;
        margin-top: 10px !important; // https://notch.atlassian.net/browse/NOT30-688
      }
    }
  }

  // additional styles for table
  .t {
    &-tableBtnNumber {
      @include width(100%);
    }
  }
}
// official-availability
@import 'theme/variables';
@import 'theme/mixins';


.mat-mdc-tooltip-surface{
  text-align: left !important;
}
.custom-tooltip {
  position: absolute;
  max-width: 249px; // https://notch.atlassian.net/browse/NOT30-794  Так же у нас будет фиксированная длина тултипа - 249пх. Если текст длиннее, то он перекидывается на следующую линию.
  @include text-9($blueDark);
  color: #fff;
  padding: 3px;
  //border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  //font-family: $main-font;
  //font-weight: $main-font-weight-400;
  line-height: 18px;
  animation: mat-mdc-tooltip-show 150ms cubic-bezier(0, 0, 0.2, 1) forwards;
  background-color: $blueDark;
  border-radius: 8px;
  //font-size: 12px;
  z-index: 1000000;
  white-space: pre-line;

  &.official-availability {
    & > div {
      position: relative;
      padding-left: 16px; // 8px
    }

    & .dot {
      position: absolute;
      top: -3px;
      left: 3px; // 0
    }

    ul {
      list-style: none;
      margin-left: 10px;
      padding-left: 0;
    }

    li {
      margin-bottom: 4px;
      color: white;
      position: relative;
      text-transform: none;
      position: relative;
    }

    li::before {
      content: '\2022';
      position: absolute;
      left: -10px;
    }

    & .title_div {
      @include text-9($white);
      padding-left: 0;
    }

    & .div_lastAvailability {
      display: flex;
      margin-top: 8px;
      @include text-10(#FFFF00);
      padding-left: 0;

      & .material-icons {
        margin-top: 2px;
        margin-right: 5px;
        font-size: 12px;
      }
    }
  }

  &::after {
    @include arrowDownForTooltip;
  }

  &--without-after {
    &::after {
      display: none;
    }
  }
}

.tooltip-trigger {
  cursor: pointer;
}

//.custom-tooltip::after {
//    content: "";
//    position: absolute;
//    bottom: -11px;
//    left: calc(50% - 6px);
//    border-width: 6px;
//    border-style: solid;
//    border-color: $blueDark transparent transparent transparent;
//}
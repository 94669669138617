//@use '@angular/material' as mat;
//@use 'sass:map';

@import 'intl-tel-input/build/css/intlTelInput.css';
// https://www.npmjs.com/package/intl-tel-input

@import 'theme/variables';
@import 'theme/other';
@import 'theme/material';
@import 'theme/material.mat-select';
@import 'theme/component-select-timezone';
@import 'theme/component-button';
@import 'theme/component-app-daterange';
@import 'theme/component-app-dropdown';
@import 'theme/component-sortby';
@import 'theme/safary';
@import 'theme/grid';
@import 'theme/tooltip';
@import 'theme/zoom';
@import 'app/shared/components/_table/meTable';
@import 'app/shared/components/_table/table-v2';

.intercom-lightweight-app {
  width: 100% !important;
  //max-width: $container-width !important;
  z-index: 100 !important;
}

.intercom-lightweight-app-launcher {
  position: absolute !important;
  top: 92vh !important;
  right: 30px !important;
}

html,
body {
  height: 100%;
  text-rendering: optimizeSpeed;
  margin: 0;
  padding: 0;
  background-color: $grey_4 !important;
  color: $blueDark;
}

@media (max-width: $size-to-switch-to-the-mobile-version) {
  app-root {
    height: 100vh;
  }

  app-root>div {
    height: 100vh;
  }
}

html {
  top: 0 !important; // не удалять. Это чтобы google autocomplete в попап окне прямо под инпутом всплывал и не скроллился
  height: 100%;
  min-height: 100vh;
  //overflow-y: scroll;
  //overflow: scroll;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  font-family: $main-font;
  font-weight: $main-font-weight-400;
  overflow: hidden;
  overflow-y: auto;
  //overflow-y: scroll;
  //overflow: scroll;
  //background-color: $grey_4; // mat-app-background index.html
  //background-color: $grey_4 !important; // mat-app-background index.html
  -webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd,
label {
  margin: 0;
  padding: 0;
}

input,
textarea,
button {
  outline: none;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    outline: none;
  }
}

input {
  padding: 0;
}

button::-moz-focus-inner {
  border: 0;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

input:read-only {
  border: none;
}

button {
  @include flexCenter();
  font-family: $main-font;
  font-weight: $main-font-weight-600;
  font-size: $btnFontSizeDesktop;
  height: $height-btnInput;
  padding: $btnPaddingDesktop;
  text-align: center;
  border: 0;
  outline: 0;
  background-color: transparent;
  appearance: none;
  cursor: pointer;
  color: $white;

  &:hover {
    opacity: 0.9;
  }

  & mat-spinner,
  & mat-mdc-spinner {
    margin-left: 10px;
  }

  &:disabled {
    @include disabled;
  }

  @media (max-width: $size-to-switch-to-the-mobile-version) {
    padding: $btnPaddingMobile !important;
    font-size: $btnFontSizeMobile !important;
  }
}

.disabled {
  opacity: 0.5;
  cursor: initial !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  margin: 0;
}

textarea {
  ///
  padding: 10px 10px 15px 10px !important;
  overflow: hidden;
}

input,
textarea {
  @include textInput($blueDark);
}

input[type='checkbox'] {
  @include width(16px);
  @include height(16px);
  accent-color: $greenLight;
}

//убрать стрелки в инпуте type='number'
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important;
  margin: 0;
}

//убрать стрелки в инпуте type='number'
input[type='number'] {
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important;
}

//============= placeholder ====================
::-webkit-input-placeholder {
  //@include textInput($blueDark);
  //color: $bordColor;
  @include textInput($colorPlaceholder);
}

::-moz-placeholder {
  //@include textInput($blueDark);
  //color: $bordColor;
  @include textInput($colorPlaceholder);
}

:-ms-input-placeholder {
  //@include textInput($blueDark);
  //color: $bordColor;
  @include textInput($colorPlaceholder);
}

:-moz-placeholder {
  //@include textInput($blueDark);
  //color: $bordColor;
  @include textInput($colorPlaceholder);
}

//=============== background input in form autocomplete =====================
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -moz-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  //  -webkit-text-fill-color: yellow !important; // text
}

@media (max-width: 700px) {

  button,
  input {
    //@include widthImportant(100%);
    @include width(100%);
  }
}

//DESKTOM
@media (min-width: $size-to-switch-to-the-mobile-version) {
  .show-only-on-mobile {
    display: none;
  }

  // .show-only-on-desktop {
  //   display: block;
  // }

  .hide-on-desktop {
    display: none !important;
  }
}

//MOBILE
@media (max-width: $size-to-switch-to-the-mobile-version) {
  .show-only-on-desktop {
    display: none;
  }

  .show-only-on-mobile {
    display: block;
  }

  .hide-on-mobile {
    display: none !important;
  }
}

.asButton22 {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  background: white;
}

.gameDescriptionSvgButton {
  padding: 5px;
  background: #f9fafb;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid #d0d5dd;
}

app-dropdown.drop-box-14-600 {
  mat-select {
    .mat-mdc-select-value,
    .mat-mdc-select-placeholder {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }
  }
  .mat-mdc-select-placeholder {
  }
}

// for all dropdowns
app-dropdown {
  mat-select {
    .mat-mdc-select-placeholder {
      color: #98a2b3;
    }
  }
}

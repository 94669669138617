@import 'theme/variables';
@import 'theme/mixins';

.o {
  &-wrap {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border: 1px solid $grey_2;
    border-radius: $border-radius-square;
    //background: linear-gradient(0deg, $grey_2, $grey_2), linear-gradient(0deg, $white, $white);
    background: $white;
  }

  &-block {
    border: 1px solid $grey_2;
    border-radius: $border-radius-square;
    background: $white;
  }

  &-fakeBlock {
    position: absolute;
    z-index: 3;
    @include widthHeightImportant(100%);
  }

  &-hidden {
    visibility: hidden !important;
    cursor: default;

    & .o-photo-pencil:before {
      visibility: hidden !important;
    }
  }

  &-hiddenAbsolute {
    position: absolute;
    z-index: -1;
    visibility: hidden !important;
    cursor: default;
  }

  &-visible {
    visibility: visible !important;
  }

  &-cur-pointer {
    cursor: pointer;
  }

  &-cur-pointer-important {
    cursor: pointer !important;
  }

  &-cur-default {
    cursor: default;
  }

  &-cur-default-important {
    cursor: default !important;
  }

  &-reverse {
    transform: scale(1, -1);
  }

  //для текста запретить перенос + ...
  &-noWrap {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  &-opacity {
    opacity: .5;
  }

  &-text-center {
    text-align: center;
  }

  &-text-center-important {
    text-align: center !important;
    @include flexCenter; ///
    //& matOption, & mat-option {
    //  text-align: center !important;
    //}
  }

  &-none {
    display: none !important; // не убирать important
  }

  &-bcg-transparent {
    background-color: transparent !important;
  }

  &-disabledBtn {
    @include fieldDisabled();
    @include disabled;
  }

  &-disabled {
    @include disabled;
  }

  &-disabledClick {
    pointer-events: none;
  }

  &-disabledClickAndOpacity {
    pointer-events: none;
    opacity: 0.5;
  }
}

// INPUT
.o {
  &-input {
    @include width(100%);
    @include height($height-btnInput);
    border-radius: $border-radius-square;
    padding: 0 10px;

    &--empty {
      @include fieldEmpty(true);
    }

    &--filled {
      @include fieldFilled(true);
    }

    &--active {
      @include fieldActive(true);
    }

    &--disabled {
      @include fieldDisabled(true);
      cursor: default !important;
    }

    &--error {
      @include fieldError(true);
    }

    &--error::placeholder {
      color: $red;
    }
  }

  &-colorPlaceholder {
    color: $colorPlaceholder;
  }

  &-nonePlaceholder {

    // на родительский надо вешать. Чтобы инпут внутри был
    input::placeholder {
      visibility: hidden;
    }
  }
}

.o-drop-down {
  @include textDropdown($blueDark);
  display: flex;
  align-items: center;
  max-width: 100%;
  height: $height-btnInput;
  padding: 1px $paddingForDrop 0 $paddingForDrop;
  border-radius: $border-radius-square;
  background-color: $white;
  border: 1px solid $blueDark;
  cursor: pointer;

  & meSvg:first-child {
    margin-right: 10px;
  }

  & meSvg:last-child {
    margin-left: 5px;
  }

  &--empty {
    @include fieldEmpty(false);
  }

  &--filled {
    @include fieldFilled(false);
  }

  &--active {
    @include fieldActive(false);
  }

  &--disabled {
    @include fieldDisabled(false);
    cursor: default !important;
  }

  &--error {
    @include fieldError(false);
  }
}

// MARGIN =================
.o {
  &-mg-t-0 {
    margin-top: 0;
  }

  &-mg-t-2 {
    margin-top: 2px;
  }

  &-mg-t-4 {
    margin-top: 4px;
  }

  &-mg-t-5 {
    margin-top: 5px;
  }

  &-mg-t-6 {
    margin-top: 6px;
  }

  &-mg-t-7 {
    margin-top: 7px;
  }

  &-mg-t-8 {
    margin-top: 8px
  }

  &-mg-t-8-important {
    margin-top: 8px !important;
  }

  &-mg-t-10 {
    margin-top: 10px;
  }

  &-mg-t-12 {
    margin-top: 12px;
  }

  &-mg-t-12-important {
    margin-top: 12px !important;
  }

  &-mg-t-14 {
    margin-top: 14px;
  }

  &-mg-t-15 {
    margin-top: 15px;
  }

  &-mg-t-16 {
    margin-top: 16px;
  }

  &-mg-t-20 {
    margin-top: 20px;
  }

  &-mg-t-22 {
    margin-top: 22px;
  }

  &-mg-t-24 {
    margin-top: 24px;
  }

  &-mg-t-25 {
    margin-top: 25px;
  }

  &-mg-t-30 {
    margin-top: 30px;
  }

  &-mg-t-32 {
    margin-top: 32px;
  }

  &-mg-t-35 {
    margin-top: 35px;
  }

  &-mg-t-36 {
    margin-top: 36px;
  }

  &-mg-t-40 {
    margin-top: 40px;
  }

  &-mg-t-45 {
    margin-top: 45px;
  }

  &-mg-t-auto {
    margin-top: auto;
  }

  &-mg-b-6 {
    margin-bottom: 6px;
  }

  &-mg-b-8 {
    margin-bottom: 8px;
  }

  &-mg-b-10 {
    margin-bottom: 10px;
  }

  &-mg-b-12 {
    margin-bottom: 12px;
  }

  &-mg-b-15 {
    margin-bottom: 15px;
  }

  &-mg-b-20 {
    margin-bottom: 20px;
  }

  &-mg-b-24 {
    margin-bottom: 24px;
  }

  &-mg-b-30 {
    margin-bottom: 30px;
  }

  &-mg-b-32 {
    margin-bottom: 32px;
  }

  &-mg-b-40 {
    margin-bottom: 40px;
  }

  &-mg-r-5 {
    margin-right: 5px;
  }

  &-mg-r-7 {
    margin-right: 7px;
  }

  &-mg-r-10 {
    margin-right: 10px !important;
  }

  &-mg-r-12 {
    margin-right: 12px !important;
  }

  &-mg-r-14 {
    margin-right: 14px !important;
  }

  &-mg-r-15 {
    margin-right: 15px !important;
  }

  &-mg-r-20 {
    margin-right: 20px !important;
  }

  &-mg-r-25 {
    margin-right: 25px !important;
  }

  &-mg-l-5 {
    margin-left: 5px !important;
  }

  &-mg-l-7 {
    margin-left: 7px !important;
  }

  &-mg-l-8 {
    margin-left: 8px !important;
  }

  &-mg-l-10 {
    margin-left: 10px !important;
  }

  &-mg-l-12 {
    margin-left: 12px !important;
  }

  &-mg-l-14 {
    margin-left: 14px !important;
  }

  &-mg-l-15 {
    margin-left: 15px !important;
  }

  &-mg-l-20 {
    margin-left: 20px;
  }

  &-mg-l-24 {
    margin-left: 24px;
  }

  &-mg-l-30 {
    margin-left: 30px;
  }

  &-mg-l-32 {
    margin-left: 32px;
  }

  &-mg-0 {
    margin: 0 !important;
  }

  &-mg-0-auto {
    margin: 0 auto;
  }

  &-mg-l-auto {
    margin-left: auto;
  }

  &-mg-l-auto-important {
    margin-left: auto !important;
  }

  &-mg-r-auto {
    margin-right: auto;
  }
}

// PADDING ==================
.o {
  &-paddingBlock {
    padding: $padding-game-desktop;

    @media (max-width: $size-to-switch-to-the-mobile-version) {
      padding: $padding-game-mobile;
    }
  }

  &-pad-t-0 {
    padding-top: 0 !important; // !!! не менять
  }

  &-pad-t-5 {
    padding-top: 5px;
  }

  &-pad-t-10 {
    padding-top: 10px;
  }

  &-pad-t-20 {
    padding-top: 20px;
  }

  &-pad-t-30 {
    padding-top: 30px;
  }

  &-pad-t-40 {
    padding-top: 40px;
  }

  &-pad-t-45 {
    padding-top: 45px;
  }

  &-pad-b-0 {
    padding-bottom: 0 !important; // !!! не менять
  }

  &-pad-b-10 {
    padding-bottom: 10px;
  }

  &-pad-b-15 {
    padding-bottom: 15px;
  }

  &-pad-b-20 {
    padding-bottom: 20px;
  }

  &-pad-r-5 {
    padding-right: 5px;
  }

  &-pad-r-7 {
    padding-right: 7px;
  }

  &-pad-r-10 {
    padding-right: 10px !important;
  }

  &-pad-r-15 {
    padding-right: 15px !important;
  }

  &-pad-l-5 {
    padding-left: 5px !important;
  }

  &-pad-l-7 {
    padding-left: 7px !important;
  }

  &-pad-l-10 {
    padding-left: 10px !important;
  }

  &-pad-l-15 {
    padding-left: 15px !important;
  }

  &-pad-l-20 {
    padding-left: 20px;
  }

  &-pad-l-30 {
    padding-left: 30px;
  }

  &-pad-0 {
    padding: 0 !important;
  }
}

// TEXT используется для передачи этих классов в дочерние компоненты =====================
.o {
  &-text-capitalize {
    text-transform: capitalize;
  }

  // text $blueDark ==========
  &-text0-blueDark,
  &-text0 {
    @include text-0($blueDark);

    & label {
      @include text-0($blueDark);
    }
  }

  &-text1-blueDark,
  &-text1 {
    @include text-1($blueDark);

    & label {
      @include text-1($blueDark);
    }
  }

  &-text2-blueDark,
  &-text2 {
    @include text-2($blueDark);

    & label {
      @include text-2($blueDark);
    }
  }

  &-text3-blueDark,
  &-text3 {
    @include text-3($blueDark);

    & label {
      @include text-3($blueDark);
    }
  }

  &-text4-blueDark,
  &-text4 {
    @include text-4($blueDark);

    & label {
      @include text-4($blueDark);
    }
  }

  &-text5-blueDark,
  &-text5 {
    @include text-5($blueDark);

    & label {
      @include text-5($blueDark);
    }
  }

  &-text6-blueDark,
  &-text6 {
    @include text-6($blueDark);

    & label {
      @include text-6($blueDark);
    }
  }

  &-text7-blueDark,
  &-text7 {
    @include text-7($blueDark);

    & label {
      @include text-7($blueDark);
    }
  }

  &-text8-blueDark,
  &-text8 {
    @include text-8($blueDark);

    & label {
      @include text-8($blueDark);
    }
  }

  &-text9-blueDark,
  &-text9 {
    @include text-9($blueDark);

    & label {
      @include text-9($blueDark);
    }
  }

  &-text10-blueDark,
  &-text10 {
    @include text-10($blueDark);

    & label {
      @include text-10($blueDark);
    }
  }


  // text $grey ==========
  &-text1-grey1 {
    @include text-1($grey_1);
  }

  &-text2-grey1 {
    @include text-2($grey_1);
  }

  &-text3-grey1 {
    @include text-3($grey_1);
  }

  &-text4-grey1 {
    @include text-4($grey_1);
  }

  &-text5-grey1 {
    @include text-5($grey_1);
  }

  &-text6-grey1 {
    @include text-6($grey_1);
  }

  &-text7-grey1 {
    @include text-7($grey_1);
  }

  &-text8-grey1 {
    @include text-8($grey_1);
  }

  &-text9-grey1 {
    @include text-9($grey_1);
  }

  &-text10-grey1 {
    @include text-10($grey_1);
  }

  // text $white ==========
  &-text1-white {
    @include text-1($white);
  }

  &-text2-white {
    @include text-2($white);
  }

  &-text3-white {
    @include text-3($white);
  }

  &-text4-white {
    @include text-4($white);
  }

  &-text5-white {
    @include text-5($white);
  }

  &-text6-white {
    @include text-6($white);
  }

  &-text7-white {
    @include text-7($white);
  }

  &-text8-white {
    @include text-8($white);
  }

  &-text9-white {
    @include text-9($white);
  }

  &-text10-white {
    @include text-10($white);
  }

  // text $newGreen ==========
  &-text1-newGreen {
    @include text-1($newGreen);
  }

  &-text2-newGreen {
    @include text-2($newGreen);
  }

  &-text3-newGreen {
    @include text-3($newGreen);
  }

  &-text4-newGreen {
    @include text-4($newGreen);
  }

  &-text5-newGreen {
    @include text-5($newGreen);
  }

  &-text6-newGreen {
    @include text-6($newGreen);
  }

  &-text7-newGreen {
    @include text-7($newGreen);
  }

  &-text8-newGreen {
    @include text-8($newGreen);
  }

  &-text9-newGreen {
    @include text-9($newGreen);
  }

  &-text10-newGreen {
    @include text-10($newGreen);
  }

  // text $greenLight ==========
  &-text1-greenLight {
    @include text-1($greenLight);
  }

  &-text2-greenLight {
    @include text-2($greenLight);
  }

  &-text3-greenLight {
    @include text-3($greenLight);
  }

  &-text4-greenLight {
    @include text-4($greenLight);
  }

  &-text5-greenLight {
    @include text-5($greenLight);
  }

  &-text6-greenLight {
    @include text-6($greenLight);
  }

  &-text7-greenLight {
    @include text-7($greenLight);
  }

  &-text8-greenLight {
    @include text-8($greenLight);
  }

  &-text9-greenLight {
    @include text-9($greenLight);
  }

  &-text10-greenLight {
    @include text-10($greenLight);
  }

  // text $red ==========
  &-text1-red {
    @include text-1($red);
  }

  &-text2-red {
    @include text-2($red);
  }

  &-text3-red {
    @include text-3($red);
  }

  &-text4-red {
    @include text-4($red);
  }

  &-text5-red {
    @include text-5($red);
  }

  &-text6-red {
    @include text-6($red);
  }

  &-text7-red {
    @include text-7($red);
  }

  &-text8-red {
    @include text-8($red);
  }

  &-text9-red {
    @include text-9($red);
  }

  &-text10-red {
    @include text-10($red);
  }
}

// FLEX ====================
.o {
  &-flex-jc-initial {
    justify-content: initial !important;
  }

  &-flex-space-between {
    display: flex;
    justify-content: space-between;
  }

  &-flex-column,
  &-flex-col {
    display: flex;
    flex-direction: column !important;
  }

  &-flex-row {
    display: flex;
    flex-direction: row !important;
  }

  &-flex-row-reverse {
    display: flex;

    @media(min-width: $size-to-switch-to-the-mobile-version) {
      flex-direction: row-reverse !important;
    }
  }

  &-flex-column-reverse {
    display: flex;
    //@media(min-width: $size-to-switch-to-the-mobile-version) { // !!! MANASA NO NEED !!! => https://notch.atlassian.net/browse/NOT30-371 => Button “Add“ should come first for mobile
    flex-direction: column-reverse !important;
    //}
  }

  &-flex-space-between {
    display: flex;
    justify-content: space-between;
  }

  &-flex-align-items {
    display: flex;
    align-items: center;
  }

  &-flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-flex-jc-start {
    display: flex;
    justify-content: flex-start;
  }

  &-flex-jc-start-important {
    display: flex;
    justify-content: flex-start !important;
  }

  //=== align-self ===
  &-flex-align-self-center {
    align-self: center;
  }

  &-flex-align-self-start {
    align-self: flex-start;
  }

  &-flex-align-self-end {
    align-self: flex-end;
  }

  &-order-1 {
    order: 1;
  }

  &-order-2 {
    order: 2;
  }

  &-order-3 {
    order: 3;
  }

  &-order-4 {
    order: 4;
  }

  &-order-5 {
    order: 5;
  }

  &-order-6 {
    order: 6;
  }

  &-order-7 {
    order: 7;
  }

  &-order-8 {
    order: 8;
  }

  &-order-9 {
    order: 9;
  }

  &-order-10 {
    order: 10;
  }
}

// WIDTH ==================
.o {
  &-widthInitial {
    //не делать здесь так  @include width(initial); влияет инпут валидации телефона
    width: initial !important;
    min-width: initial !important;
    max-width: initial !important;
  }

  &-w-0 {
    @include widthImportant(0);
  }

  &-w-100 {
    @include widthImportant(100%);
  }

  &-w-50 {
    @include widthImportant(50%);
  }
}

// BUTTON ===================
.o {
  &-btn {
    @include flexCenter;

    &-square-bluedark,
    &-square-blueDark {
      color: white;
      background-color: $blueDark;
      border-radius: $border-radius-square;
      @include text5Btn($grey_4);
    }

    &-square-greenLight {
      background-color: $greenLight;
      border-radius: $border-radius-square;
      @include text5Btn($blueDark);
    }

    &-square-newGreen {
      background-color: $newGreen;
      border-radius: $border-radius-square;
      @include text5Btn($blueDark);
    }

    &-square-white {
      background-color: $white;
      border-radius: $border-radius-square;
      @include text5Btn($blueDark);
      border: 1px solid $grey_2;
    }

    &-square-grey1,
    &-square-grey_1 {
      background-color: $grey_1;
      border-radius: $border-radius-square;
      @include text5Btn($blueDark);
      border: 1px solid $blueDark;
    }

    &-square-grey2,
    &-square-grey_2 {
      background-color: $grey_2;
      border-radius: $border-radius-square;
      @include text5Btn($blueDark);
      border: 1px solid $grey_1;
    }

    &-square-grey3,
    &-square-grey_3 {
      background-color: $grey_3;
      border-radius: $border-radius-square;
      @include text5Btn($blueDark);
      border: 1px solid $grey_2;
    }

    &-square-grey3_5,
    &-square-grey_3_5 {
      background-color: $grey_3_5;
      border-radius: $border-radius-square;
      @include text5Btn($blueDark);
      border: 1px solid $grey_2;
    }

    &-square-grey4,
    &-square-grey_4 {
      background-color: $grey_4;
      border-radius: $border-radius-square;
      @include text5Btn($blueDark);
      border: 1px solid $grey_2;
    }

    &-square-red {
      background-color: $red;
      border-radius: $border-radius-square;
      @include text5Btn($white);
    }

    &-circle-bluedark,
    &-circle-blueDark {
      background-color: $blueDark;
      border-radius: $border-radius-circle;
      @include text7Btn($grey_4);
    }

    &-circle-greenLight {
      background-color: $greenLight;
      border-radius: $border-radius-circle;
      @include text7Btn($blueDark);
    }

    &-circle-newGreen {
      background-color: $newGreen;
      border-radius: $border-radius-circle;
      @include text7Btn($blueDark);
    }

    &-circle-white {
      background-color: $white;
      border-radius: $border-radius-circle;
      @include text7Btn($blueDark);
      border: 1px solid $grey_2;
    }

    &-circle-red {
      background-color: $red;
      border-radius: $border-radius-circle;
      @include text7Btn($white);
    }
  }

  &-btn-back {
    justify-content: flex-start;
    @include width(75px);
    padding: 0;
    @include textInput($blueDark);
    cursor: pointer;

    & span {
      margin-left: 8px;
    }
  }
}

// POSITION (absolute, relative)
.o {
  &-pos-absolute {
    position: absolute;
  }

  &-pos-relative {
    position: relative;
  }

  &-zIndex10 {
    z-index: 10;
  }
}

// === HEIGHT ===================
.o {
  &-height0 {
    height: 0 !important;
    min-height: 0 !important;
    max-height: 0 !important;
  }

  &-height100percent {
    height: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
  }
}

// BACKGROUND =================
.o {
  &-bcg-white {
    background: $white !important;
  }

  &-bcg-grey1 {
    background: $grey_1 !important;
  }

  &-bcg-grey4 {
    background: $grey_4 !important;
  }
}

// COLOR =====================
.o {

  &-colorWhite,
  &-color-white {
    color: $white !important;
  }

  &-colorGreenLight,
  &-color-greenLight {
    color: $greenLight !important;
  }

  &-colorNewGreen,
  &-colornewGreen,
  &-color-newGreen {
    color: $newGreen !important;
  }

  &-colorBlueDark,
  &-color-blueDark {
    color: $blueDark !important;
  }

  &-colorRed,
  &-color-red {
    color: $red !important;
  }

  &-colorYellow,
  &-color-yellow {
    color: $yellow !important;
  }

  &-colorGrey1,
  &-color-grey1 {
    color: $grey_1 !important;
  }

  &-colorGrey3,
  &-color-grey3 {
    color: $grey_3 !important;
  }

  &-colorGreen {
    color: $green !important;
  }
}

// BORDER ===============
.o {

  &-bord-none,
  &-bordNone {
    border: none !important;
  }

  &-bord-newGreen {
    border: 1px solid $newGreen !important;
  }

  &-bord-red {
    border: 1px solid $red !important;
  }

  &-bord-grey1 {
    border: 1px solid $grey_1;
  }

  &-bordColor {
    border: 1px solid $bordColor;
  }

  &-bord-bottom {
    border-bottom: 1px solid $bordColor;
  }

  &-bord-bottom-grey2,
  &-bordBottomGrey2 {
    border-bottom: 1px solid $grey_2;
  }
}

//=== TABLE =========================================
.o-wrapForPageTable {

  // andrei удалить когда фильтры будут готовы
  &__forMobileHeader,
  &__filtersForMobile {
    display: none;
  }

  @media (max-width: $size-to-switch-to-the-mobile-version) {
    margin: 32px auto;
    padding: 0 16px 50px 16px;
    background: $white;

    & linkPage,
    &__forDesktopHeader,
    & pagination,
    & paginationWithSettings {
      display: none !important;
    }

    &__forMobileHeader {
      @include flex;
      justify-content: space-between;
      margin-top: 24px;

      & dropFormCtrl,
      & app-dropdown,
      & inputCtrl {
        @include width(calc(100% - 44px - 11px)); // 44px=SVG 11px=margin
      }
    }

    // andrei delete
    &__filtersForMobile {
      //=== FILTERS FOR MOBILE ========================
      position: fixed;
      top: $heightHeaderNavigation;
      right: 0;
      z-index: 999;
      @include widthImportant(100%);
      @include height(calc(100% - $heightHeaderNavigation));
      @include flex;

      &__bcg {
        //@include width(12.8%);
        @include width(45px);
        @include height(100%);
        background: #000000;
        opacity: .6;
      }

      &__content {
        @include flexColumn;
        //@include width(87.2%);
        @include width(calc(100% - 45px));
        @include height(100%);
        background: $white;
        padding: 24px 16px;

        & headTitle {
          margin-bottom: 24px;
        }

        & inputCtrl,
        & dropDateRange,
        & dropFormCtrl,
        & app-dropdown {
          @include widthImportant(100%);
          margin-bottom: 16px;
        }

        & btnWrap {
          margin-top: auto;
        }
      }
    }
  }
}

//=== TABLE =========================================
.o-table {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  border: 1px solid $grey_2;
  box-shadow: 0 4px 35px rgba(0, 16, 56, 0.06);
  border-radius: 8px;

  &__header {
    @include height(44px);
    display: flex;
    align-items: center;
    background-color: $grey_4;
    border-bottom: 1px solid $grey_3;
    border-radius: 8px 8px 0 0;
    //@include textTableHeader;

    &__item {
      @include flex;
      @include text-7($blueDark);

      &__chx {
        margin-right: 12px;
      }

      &__arrow {
        margin-left: 4px;
      }
    }
  }

  &__headerText {
    @include textTableHeader;
  }

  &__list {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 8px 8px;

    &__item {
      width: max-content;
      min-height: 72px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $grey_3;
      @include text-8($blueDark);
      background-color: $white;

      // andreu delete later
      &__name {
        display: flex;
        align-items: center;
        @include text-7($blueDark);

        & photo,
        & span {
          cursor: pointer;
        }

        & meSvg {
          margin-right: 12px;
        }
      }

      &--colorGrey3 {
        color: $grey_3 !important;

        & .o-table__list__item__name {
          color: $grey_3 !important;
        }
      }

      &--colorRedOpacity {
        color: red !important;
        opacity: 0.5;

        // & * {
        //   color: $red !important;
        // }

        // // andreu delete later
        & .o-table__list__item__name {
          color: $red !important;
        }

        & .o-table__ceil__name {
          color: $red !important;
        }
      }

      &--checked,
      &--isSelect {
        background-color: $greenLight2;
      }

      &--colorBlueOpacity {
        background: rgba(20, 61, 111, 0.10);
      }

      //&--nextLineRight {
      //  @include flex;
      //}
    }

    &__item:last-child {
      border-bottom: none;
      border-radius: 0 0 8px 8px;
    }
  }

  &__ceil {
    @include flexColumn;

    &__content {
      @include flexColumn;

      &__comma {
        display: none;
      }
    }

    & .o-table__headerText {
      display: none;
    }

    &--name {
      display: flex;
      align-items: center;
      @include text-7($blueDark);

      & photo,
      & span {
        cursor: pointer;
      }

      & meSvg {
        margin-right: 12px;
      }
    }
  }

  &--itemRow {}

  &--itemColumn {
    display: flex;
    flex-direction: column;

    &__twoLine {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
    }
  }

  & span,
  & div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__mobileHeader,
  &__mobileBtns {
    display: none;
  }

  @media (max-width: $size-to-switch-to-the-mobile-version) {
    border: none;
    box-shadow: none;

    &__header {
      display: none;
    }

    &__list {
      &__item {
        @include width(100%);
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $grey_2;
      }
    }

    &__ceil {

      //border-bottom: none !important;
      & .o-table__headerText {
        display: flex;
      }

      &__content {
        flex-direction: row;

        &__comma {
          display: block;
        }
      }
    }

    & .widthItem {
      display: flex;
      justify-content: center;
      @include width(100%);
      @include height(70px);
      padding-left: 0 !important;
      border-bottom: 1px solid $grey_2;
    }

    &__mobileHeader {
      @include flexCenter;
      @include text-7($blueDark);
      @include width(100%);
      @include height(36px);
      border: 1px solid $grey_2;
      border-radius: 8px;
      background: $grey_4;
      // gameStatus == 'ACTIVE' | 'CLOSED' |'CANCELLED' |'APPROVED' |'OPEN' |'FILLED' |'COMPLETED' |'UNAVAILABLE'
      //  &--PENDING {}
    }

    &__mobileBtns {
      display: initial;
      @include width(100%);

      & btn,
      & button {
        @include height(36px);
        @include width(100%);
        margin-top: 12px;
      }

      & btn:first-child,
      & button:first-child {
        margin-top: 0;
      }
    }
  }
}

//.o-filterSearchForMobile {
//  //& inputCtrl {
//  & filterSearch {
//    @include width(calc(100% - 56px));
//  }
//}

//=== TOOLTIP ====================================
//.o-tooltip {
//  @include tooltip-default;
//}

@media (max-width: $size-to-switch-to-the-mobile-version) {
  pagination.ng-star-inserted {
    @include widthImportant(100%);
  }

  .table-grid {
    //padding: 16px;
    border: none;

    .t-body {
      &__row {
        width: initial;
        margin-bottom: 5px;
        border: none;
        flex-direction: row;

        &__cell {
          width: initial;
          max-width: initial;
          min-width: initial;
          flex: 0 0 100%;
          justify-content: center;
          border-bottom: 1px solid $grey_2;
          padding: 10px 0;

          &--without-border {
            border-bottom: none;
          }

          &:nth-child(1) {
            border: none;
          }

          &--game-number {
            justify-content: center;

            & .o-table__chx {
              display: none;
            }
          }

          & .game-title,
          button.game-title {
            @include text-7($blueDark);
            display: flex;
            width: 100%;
            height: 36px;
            padding: 4px 12px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;

            border-radius: 8px;
            border: 1px solid $grey_2;
            background: $grey_4;
            mix-blend-mode: multiply;
          }

          & button.manage-btn {
            display: flex;
            height: 36px;
            width: 100%;
            margin-bottom: 5px;
            padding: 8px 14px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            border-radius: 8px;
            background: $blueDark;

            color: #FFF;
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.2px;
          }

          & [role=heading] {
            display: block;
            color: $blueDark;
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.2px;
          }

          &--d-block {
            display: block;
          }
        }

        &:nth-last-child(1) {
          & .t-body__row__cell:last-child {
            border: none;
          }
        }
      }
    }
  }
}

// !!! исправить потом
.game-title {
  @include text-7($blueDark);
}

// ======================
.o-insteadAnimation {
  position: relative;

  &__item {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  &__true {
    // isActive
    z-index: 2;
  }
}

// for text-message components
.o-iframe {
  display: flex;
  align-items: center;
  justify-content: center;

  & iframe {
    height: 630px;
    max-height: 630px;
    border-radius: $border-radius-square;
  }
}

//  если пришел массив строк и надо вывести в блоке и переносить на след строку если не влазит в ширину блока
.o-arrStrWrap {
  display: flex;
  flex-wrap: wrap;
  @include heightImportant(max-content);

  //@include height(max-content);
  //align-self: baseline;
  & span {
    @include height(24px); // когдда емайл то почему то пробелы добавляются и высота становится 48px
  }
}

.o-blockUploadFile {
  @include width(100%);
  @include flexCenter;
  flex-direction: column;
  margin-top: 16px;
  padding: 16px 0;
  border: 1px solid $grey_2;
  border-radius: 12px;

  & input {
    display: none;
  }

  &__title {
    @include text-7($blueDark);
  }

  & btn,
  & label,
  & a {
    @include height(40px);
    margin-top: 12px;
    padding: 8px 16px;
    cursor: pointer;

    & meSvg {
      margin-right: 10px;
    }
  }

  &__rowLink {
    @include flexCenter;

    &__link {
      @include text-7($newGreen);
      line-height: 20px;
    }

    &__text {
      @include text-8($blueDark);
      line-height: 20px;
      margin-left: 6px;
    }
  }
}

//=== for contact component ==============================
.o-itemEditContact {
  min-height: 56px;
  display: flex;
  justify-content: space-between;
  //padding: 20px 0;
  //margin-top: 20px;
  padding-top: 20px;

  & inputCtrl,
  & dropFormCtrl,
  & selectdate,
  & app-dropdown {
    //@include width(100%);
    //width: 100%;
    width: $widthForContactCol_2;
    //@include width($widthForContactCol_2);
    //margin-left: 12px;
  }

  @media (max-width: $size-to-switch-to-the-mobile-version) {
    & selectdate {
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  &__col_1 {
    @include flexColumn;
    @include width($widthForContactCol_1);
    @include text-7($blueDark);

    & div {
      @include flexColumn;
    }

    & btn {
      align-self: flex-start;
      margin-left: auto;
    }

    & span {
      @include text-8($blueDark);
      padding: 0;
    }
  }

  &__col_2 {
    @include width($widthForContactCol_2);
    //@include width(calc(100% - $widthForContactCol_1));
    @include flexColumn;

    & btn {
      margin-top: 16px;
    }
  }

  &__photo {
    @include flex;
    //justify-content: flex-start;
    @include width($widthForContactCol_2);

    & photo {
      margin-right: 20px;
    }

    & btn:last-child {
      margin-left: 8px;
    }
  }

  &__name {
    //@include width(calc(100% - $widthForContactCol_1));
    @include width($widthForContactCol_2);
    @include flex;
    justify-content: space-between;

    & inputCtrl {
      //@include width(calc(100% - 12px));
      @include width(244px);
    }
  }

  &--border {
    padding-bottom: 20px;
    border-bottom: 1px solid $grey_2;
  }

  &--no-border {
    padding-bottom: 20px;
  }

  &__title {
    @include width(max-content);
    @include text-4($blueDark);
  }

  &__logoNameBtn {
    @include flex;
    @include width($widthForContactCol_2);
    @include text-7($blueDark);

    & photo {
      margin-right: 8px;
    }

    & btn {
      margin-left: 20px;
    }
  }

  &:first-child {
    padding-top: 0;
  }

  @media (max-width: $size-to-switch-to-the-mobile-version) {

    & inputCtrl,
    &__col_2,
    &__photo,
    &__name,
    &__logoNameBtn {
      @include width(100%);
    }
  }
}

.test {
  display: none; // !!! for test
  position: absolute;
  top: 10px;
  background-color: $bordColor;
  color: $blueDark;
  padding: 20px;
  left: 0;
  font-size: 33px;
  z-index: 99999;

  & div {
    margin: 10px;
    cursor: pointer;

    &:hover {
      color: #21C5F8;
    }
  }

  & div:first-child {
    cursor: default;

    &:hover {
      color: $blueDark;
    }
  }
}

.testMatMenu {
  display: none;
  position: absolute;
  color: $blueDark;
  font-size: 33px;
  z-index: 99999;
  @include widthHeight(5px);
  top: 0;
  left: 0;
  padding: 0 !important;
}

.width-50-perc {
  width: 50%;
}

.width-100-perc {
  width: 100%;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

//=== for :host {} ===========================
:host {
  display: block;
  //@include flexColumn;
  width: 100%;
}

// !!! games || payments || payments/assignorFees/all
// !!! o-wrapPage & o-header => в этих css-классах ничего не менять.
// !!! Если нужны какие-то дополнительные изменения, то в нужном компоненте можно эти css-классы переписать
.o-wrapPage {
  @include width(100%);
  @include flexColumn;
  align-items: center;
  margin: $marginTopPageFromMenu_forDesktop auto;
  padding: 0 $paddingLeftHeaderNavigation;

  // andrei need delete later
  & linkPage {
    align-self: flex-start;
    display: block;
    margin-bottom: 52.5px;
  }

  // !!!это для дочерних роутов. Тоесть если переключатель страниц(linkPage) находится в родительском роуте. Пример смотреть на страницах пайментов
  &--noMarginTopAndNoPadding {
    margin: 0 auto;
    padding: 0;
  }

  & pagination,
  & paginationWithSettings {
    margin-top: 28px;
  }

  @media (max-width: 1540px) {
    padding-left: 0;
    padding-right: 0;

    // andrei need delete later
    & linkPage {
      padding: 0 $paddingLeftHeaderNavigation;
    }
  }

  @media (max-width: $size-to-switch-to-the-mobile-version) {
    // $size-to-switch-to-the-mobile-version
    @include height(100vh); // for download content from server by scroll
    overflow-y: scroll; // for download content from server by scroll
    @include width(100%);
    margin: 0 auto;
    padding: $marginTopPageFromMenu_forMobile $paddingPageLeftRight_forMobile 50px $paddingPageLeftRight_forMobile;

    // andrei need delete later
    & linkPage {
      display: none;
    }

    &--noMarginTopAndNoPadding {
      margin: 0 auto;
      padding: 0;
    }
  }
}

.o-header {
  @include width(100%); // !!! no delete. if delete => ruined design
  @include flexColumn;
  //align-items: center;

  &.no-with {
    width: auto;
    min-width: auto;
    max-width: none;
  }

  &__linkPage {

    // andrei need delete later
    & linkPage {
      display: block;
      margin-bottom: 52.5px;
    }

    // andrei need delete later
    & app-dropdown,
    & app-filtersSelectedIcon {
      display: none;
    }
  }

  &__content {
    @include width(100%);
    @include flexColumn;
    margin: 0 auto;
  }

  &__title {
    font-size: 28px;
    font-weight: 700;
    //display: block;
    @include flex;
    @include text-1($blueDark);

    //margin-top: 52.5px;
    & meSvg {
      margin-right: 10px;
    }
  }

  &__subTitle {
    @include flex;
    @include text-6($blueDark);
    margin-top: 12px;

    & meSvg {
      margin-left: 5px;
    }
  }

  &__filters,
  &__wrapActions {
    @include flex();
    margin-top: 24px;
  }

  &__actions {
    @include flex();
    margin-left: auto;

    & meSvg,
    & btn {
      margin-left: 6px;
    }
  }

  & app-filtersSelected {
    margin-top: 12px;
  }

  // andrei need delete later &--noHaveToggleCurrentPast
  &--noHaveToggleCurrentPast,
  &--noHaveLinkPage {
    // !!! comment from NOT30-371: "The distance from the navigation to the “Open Games“ should be 60px"
    // !!! css class "o-wrapPage" have margin-top: 20.5px. И поэтому здесь просто добавляется недостающие 39.5px
    // !!! это для страниц на которых нет перключение вкладок current/past
    margin-top: 39.5px;
  }

  @media (max-width: $size-to-switch-to-the-mobile-version) {
    &__title {
      font-size: 24px;
      font-weight: 600;
    }

    @include width(100%);

    //align-items: flex-start;
    &__linkPage {
      @include width(100%);
      @include flex();
      justify-content: space-between;
      margin-top: 24px;

      & linkPage {
        display: none;
      }

      & dropFormCtrl,
      & app-dropdown,
      & app-filtersSelectedIcon {
        display: block;
      }

      & dropFormCtrl,
      & app-dropdown {
        @include width(calc(100% - 55px));
      }
    }

    &__content {
      order: -1;
      @include widthImportant(100%);
      align-items: flex-start;
    }

    &__title {
      @include text-overflow(calc(100% - 45px));
      @include widthImportant(calc(100% - 45px));
      //@include text-overflow(100%);
      //order: -1; // __content
      //&--column {
      //  flex-direction: column;
      //}
    }

    &__filters {
      position: absolute;
      @include widthImportant(100%);
    }

    &__wrapActions {
      @include widthImportant(100%);
    }

    &__actions {
      display: none;
    }

    & app-filtersSelected {
      display: none;
    }

    // andrei need delete later &--noHaveToggleCurrentPast
    &--noHaveToggleCurrentPast {
      flex-direction: row;
      align-items: center;
      margin-top: 0;

      & .o-header__linkPage {
        @include width(initial);
        margin-top: 0;
        margin-left: auto;
      }

      & .o-header__content {
        @include widthImportant(calc(100% - 55px));
      }
    }

    &--noHaveLinkPage {
      margin-top: 0;
    }
  }
}

.o-content {
  @include flexColumn;

  //display: flex;
  //justify-content: center;
  //@include width(100%); // !!! назначать в компонентах. Т.к. ширина разная у всех страниц
  @media (max-width: $size-to-switch-to-the-mobile-version) {
    @include widthImportant(100%);
  }
}

.checkActiveGames {
  width: 400px !important;
}

.iti__dropdown-content {
  margin-bottom: 9px;
  margin-top: 12px;
  min-width: 324px;
  margin-left: -17px;
}

@media (max-width: $size-to-switch-to-the-mobile-version) {
  .panel-standart-mobile {
    border: 1px solid $grey_2;
    border-radius: 8px;
    padding: 0 16px;
    background-color: white;
  }

  .margin-top-mobile-16 {
    margin-top: 16px;
  }
}



.visible-hide {
  visibility: hidden;
}

.st-pading-from-menu {
  margin-top: 60px;

  @media (max-width: $size-to-switch-to-the-mobile-version) {
    margin-top: 32px;
  }
}

.st-page {
  margin-top: 60px;

  @media (max-width: $size-to-switch-to-the-mobile-version) {
    margin-top: 32px;
    padding: 0 16px;
  }
}

.st-btn {
  height: 44px;

  @media (max-width: $size-to-switch-to-the-mobile-version) {
    height: 36px;
  }
}

.st-mt-24 {
  margin-top: 24px;

  @media (max-width: $size-to-switch-to-the-mobile-version) {
    margin-top: 16px;
  }
}

.st-ml-8 {
  margin-left: 8px;
}

.st-mr-8 {
  margin-right: 8px;
}

.st-ml-24 {
  margin-left: 24px;

  @media (max-width: $size-to-switch-to-the-mobile-version) {
    margin-left: 16px;
  }
}

.st-ml12 {
  margin-left: 12px;
}

.st-message {
  font-weight: 600;
  font-size: 14px;
  padding: 4px 12px 4px 12px;
  border-radius: 8px;
  line-height: 24px;

  &.st-green {
    background: #ECFDF3;
    color: #027A48;
  }

  &.st-red {
    background: #FEF3F2;
    color: #B42318;
  }

  &.st-green {
    color: #027A48;
    background: #ECFDF3;
  }

  &.st-gray {
    color: #07254a;
    background: #EAECF0;
  }
}

.st-headTitle-wraper {
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: $size-to-switch-to-the-mobile-version) {
    flex-direction: column;
    align-items: flex-start;
  }

  headtitle {
    .wrapperHeadTitle__text {
      @media (max-width: $size-to-switch-to-the-mobile-version) {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }

}

.st-error-color {
  color: #F12B2C;
}

.st-600 {
  font-weight: 600;
}

.st-fs-16 {
  font-size: 16px;
}

.st-first-letter-up {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}
@import 'variables';
@import 'mixins';

button.mat-calendar-body-in-range::before {
  background: $greenLight2;
}

span.mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected {
  background: $newGreen;
}

// линия снизу
.mdc-line-ripple {
  display: none !important;
}

.official-tooltip .mdc-tooltip__surface {
  max-width: unset !important;
}

.official-tooltip-multi-line {
  white-space: pre-line;

  & .mdc-tooltip__surface {
    text-align: left;
    max-width: unset;
    max-height: unset;
  }
}

// стрелка
.mat-mdc-select-arrow {
  display: none !important;
}

@media (min-width: 1339px) {
  .cdk-overlay-pane {
    width: max-content;
  }
}

// для Mat-menu и для mat select
.cdk-overlay-pane {
  width: max-content;

  transform: translate(0, 4px);
  overflow-x: hidden;

  & .mat-mdc-select-panel {
    @include widthImportant(100%);
    overflow-x: hidden !important;
    padding: 0 !important;
    border-radius: $border-radius-square !important;

    & .mat-mdc-optgroup-label,
    & .mat-mdc-option {
      @include matOption;

      &:hover:not(.mdc-list-item--disabled),
      &:focus:not(.mdc-list-item--disabled),
      &-active,
      &.mdc-list-item--selected:not(.mdc-list-item--disabled) {
        background-color: $colorHover !important;
      }

      & .mdc-list-item {
        &__primary-text {
          @include width(100%);
          color: $blueDark !important;
          @include text-overflow(100%);
        }
      }
    }
  }
}

.mat-mdc-form-field-icon-prefix {
  @include heightImportant(100%);
  display: flex;
  align-items: center;
  padding: 0 8px 0 0 !important;
}

.mat-mdc-menu-panel {
  // for phone prefix
  @include widthImportant(100%);
}

.o {
  &-matForm {
    @include width(100%);
    height: 100%;
    border-radius: $border-radius-square !important;
    background-color: transparent;

    & .mdc-text-field--outlined .mdc-notched-outline {
      display: none !important;
    }

    & .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    & .mat-mdc-form-field-focus-overlay {
      background-color: $colorHover;
    }

    & .mat-form-field-flex,
    & .mat-mdc-text-field-wrapper {
      @include width(100%);
      @include height($height-btnInput);
      padding: 0 14px 0 $paddingForDrop !important;
      background-color: transparent !important;
      border-radius: $border-radius-square;
      outline: none;
      // @media (width<=$size-to-switch-to-the-mobile-version) {
      //   @include height($height-btnInputMobile);
      // }
    }

    & .mat-mdc-form-field-flex,
    & .mat-mdc-form-field-infix {
      @include height($height-btnInput);
      // @media (width<=$size-to-switch-to-the-mobile-version) {
      //   @include height($height-btnInputMobile);
      // }
    }

    &--transparent {
      & .mat-mdc-text-field-wrapper {
        background-color: transparent !important;
      }
    }

    &--white {
      & .mat-mdc-text-field-wrapper {
        background-color: $white !important;
      }
    }

    &--grey_1 {
      & .mat-mdc-text-field-wrapper {
        background-color: $grey_1 !important;
      }
    }

    &--grey_2 {
      & .mat-mdc-text-field-wrapper {
        background-color: $grey_2 !important;
      }
    }

    &--grey_3 {
      & .mat-mdc-text-field-wrapper {
        background-color: $grey_3 !important;
      }
    }

    &--grey_4 {
      & .mat-mdc-text-field-wrapper {
        background-color: $grey_4 !important;
      }
    }

    &--blueLightOpacity {
      & .mat-mdc-text-field-wrapper {
        background-color: $grey_1 !important;
      }
    }

    &--textarea {
      & .mat-mdc-text-field-wrapper,
      & .mat-mdc-form-field-infix,
      & .mat-mdc-form-field-flex {
        @include height(initial);
      }
    }

    &--empty {
      & .mat-mdc-text-field-wrapper {
        @include fieldEmpty();
      }

      & .mat-form-field-label,
      & .mat-mdc-form-field-label,
      & input::placeholder,
      & .mdc-text-field__input {
        color: $colorPlaceholder !important;
      }

      & .mat-select-arrow-wrapper:before,
      & .mat-mdc-select-arrow-wrapper:before {
        @include enlargeBcgImg('../assets/arrow/arrow_chevronBottomGrey&20.svg');
      }
    }

    &--filled {
      & .mat-mdc-text-field-wrapper {
        @include fieldFilled();
      }

      & .mat-form-field-label,
      & .mat-mdc-form-field-label,
      & input::placeholder,
      & .mdc-text-field__input {
        color: $blueDark !important;
      }

      & .mat-select-arrow-wrapper:before,
      & .mat-mdc-select-arrow-wrapper:before {
        @include enlargeBcgImg('../assets/arrow/arrow_chevronBottom&20.svg');
      }
    }

    &--active {
      cursor: default !important;

      & .mat-mdc-text-field-wrapper {
        @include fieldActive();
      }

      & .mat-form-field-label,
      & .mat-mdc-form-field-label,
      & input::placeholder,
      & .mdc-text-field__input {
        color: $blueDark !important;
      }

      & .mat-select-arrow-wrapper:before,
      & .mat-mdc-select-arrow-wrapper:before {
        @include enlargeBcgImg('../assets/arrow/arrow_chevronBottom&20.svg');
        transform: scale(1, -1);
      }
    }

    &--disabled {
      cursor: default !important;

      & .mat-mdc-text-field-wrapper {
        @include fieldDisabled();
      }

      & .mat-form-field-label,
      & .mat-mdc-form-field-label,
      & input::placeholder,
      & .mdc-text-field__input {
        color: $colorPlaceholder !important;
      }

      & .mat-select-arrow-wrapper:before,
      & .mat-mdc-select-arrow-wrapper:before {
        @include enlargeBcgImg('../assets/arrow/arrow_chevronBottomGrey&20.svg');
      }
    }

    &--error {
      & .mat-mdc-text-field-wrapper {
        @include fieldError();
      }

      & .mat-form-field-label,
      & .mat-mdc-form-field-label,
      & input::placeholder,
      & .mdc-text-field__input,
      & textarea::placeholder {
        color: $red !important;
      }

      & .mat-select-arrow-wrapper:before,
      & .mat-mdc-select-arrow-wrapper:before {
        @include enlargeBcgImg('../assets/arrow/arrow_chevronBottomRed&20.svg');
      }
    }

    //=== HEIGHT ==================
    &--150 {
      @include height(150px);

      & .mat-form-field-flex,
      & .mat-mdc-text-field-wrapper,
      & .mat-mdc-form-field-flex,
      & .mat-mdc-form-field-infix {
        @include height(150px);
      }

      & .mat-mdc-form-field-infix {
        padding-top: 9px !important;
      }

      & textarea {
        min-height: 150px !important;
        padding: 0 !important;
      }
    }

    &--44 {
      & .mat-mdc-form-field-infix {
        padding-top: 9px !important;
      }
    }

    &--32 {
      & .mat-mdc-form-field-infix {
        padding-top: 3px !important;
      }
    }
  }
}

.hiddenMatOption {
  visibility: hidden !important;
  @include heightImportant(0);
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
}

.mat-mdc-form-field-infix {
  padding-top: 8px !important;
  // @media (width < $size-to-switch-to-the-mobile-version) {
  //   padding-top: 5px !important;
  // }

  & input,
  & textarea {
    border: none !important;
    background-color: inherit !important;
  }
}

.mdc-text-field {
  &__input::placeholder {
    color: $colorPlaceholder !important;
  }
}

.mat-datepicker-content {
  border-radius: $border-radius-square !important;
}

// === MAT-MENU ====================================================
.mat-mdc-menu-panel {
  min-height: $height-btnInput !important;
  max-width: initial !important;
  border-radius: $border-radius-square !important;
  box-shadow: none !important;
  border: 1px solid $grey_2;

  & .mat-mdc-menu-content {
    padding: 0 !important;

    & button,
    & a {
      @include matMenuItem;

      &:hover {
        background-color: $colorHover !important;
      }
    }

    & .mat-mdc-menu-item .mdc-list-item__primary-text {
      @include matMenuItem;
      padding: 0;
    }
  }
}

.mat-mdc-radio {
  &-button {
    & label {
      @include text-6($blueDark);
    }

    & .mdc-radio__outer-circle,
    & .mdc-radio__inner-circle {
      border-color: $blueDark !important;
    }
  }

  &-checked,
  &-isSelect {
    & label {
      @include text-6($greenLight);
    }

    & .mdc-radio__outer-circle,
    & .mdc-radio__inner-circle {
      border-color: $greenLight !important;
    }
  }
}

.mdc-radio {
  padding: 0 !important;

  &__background {
    &::before {
      background-color: transparent !important;
    }
  }

  &__native-control {
    width: 20px !important;
    height: 20px !important;
  }
}

.mat-mdc-radio-touch-target {
  width: 20px !important;
  height: 20px !important;
}

//=== ENLARGE ==============
.enlargeReverse {
  & .mat-select-arrow,
  & .mat-mdc-select-arrow {
    transform: scale(1, -1);
  }
}

//=== MAT DIALOG ================
.mat-mdc-dialog-surface {
  padding: $padding-game-desktop;

  @media (max-width: $size-to-switch-to-the-mobile-version) {
    padding: $padding-game-mobile;
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  box-shadow: none !important;
}

.mat-mdc-expansion-panel {
  box-shadow: none !important;
  border: 2px solid $blueDark !important;
  background-color: $white !important;
  font-size: 16px !important;
  color: $blueDark !important;
  margin-bottom: 30px !important;
  cursor: pointer !important;
}

.mat-mdc-expansion-panel.default-method {
  background: $grey_1 !important;
}

//=== убрать скролл в mat-select
::-webkit-scrollbar {
  @include widthImportant(0);
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

//=== checkbox
.mat-pseudo-checkbox {
  @include widthHeight(20px);
  margin-right: 8px !important;
  background: $white !important;
  border: 1px solid $grey_2 !important;
  border-radius: 4px !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background-color: $newGreen !important;
  border: 1px solid $newGreen !important;
  border-radius: 4px !important;
}

//=== CALENDAR ===
.mat-mdc-icon-button.mat-mdc-button-base {
  @include widthHeightImportant(20px);
  display: flex;
  justify-content: flex-start;
  padding: 12px 0 !important; // чтобы иконка календаря вертикально по центру
}

.mat-calendar-body-cell-content.mat-focus-indicator {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  color: $blueDark !important;
}

.mat-calendar {
  font-family: $main-font;
  font-weight: $main-font-weight-400;
  color: $blueDark;
  border-radius: 8px;
  border: 1px solid $grey_2;

  &-body {
    &-selected {
      background: $newGreen;
    }

    &-in-range::before {
      background: $grey_4;
    }

    & tr[aria-hidden='true'] {
      display: none !important;
    }
  }

  &-controls {
    justify-content: space-between !important;
  }

  // стрелочки в окне при выборе даты
  &-previous-button,
  &-next-button {
    @include widthHeightImportant(24px);
    margin: 0 12px;

    &::after {
      @include flexCenter;
      background-size: 24px;
      background-repeat: no-repeat !important;
      margin: 0 !important;
      border: none !important;
      transform: none !important;
    }
  }

  &-previous-button {
    order: -1 !important;

    &::after {
      background-image: url('../assets/arrow/arrow_chevronLeftGrey&24.svg');
    }
  }

  &-next-button::after {
    background-image: url('../assets/arrow/arrow_chevronRightGrey&24.svg');
  }

  &-spacer {
    display: none;
  }
}

// !!! hover на дату в календаре
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background: $greenLight2 !important;
}

// !!! для выбраного диапозона дат в календаре
.mat-calendar-body-in-range::before {
  background: $greenLight2;
}

//=== всплывающая подсказка mat-tooltip // CSS for matTooltip
.mdc-tooltip__surface {
  @include text-9($white);
  font-family: $main-font !important;
  font-weight: $main-font-weight-600 !important;
  background-color: $blueDark !important;
  border-radius: $border-radius-square !important;
  padding: 8px !important;
}

.matTooltipRed {
  & .mdc-tooltip__surface {
    background-color: $red !important;
  }
}

.mat-drawer,
.mat-drawer-inner-container,
.mat-drawer-content {
  @extend .g-flex-column;
}

.mat-drawer-inner-container {
  @extend .g-flex-column__item;
}

//=== в правом верхнем углу кружок =======================================
mesvg.mat-badge-medium .mat-badge-content {
  background-color: $blueDark;
  font-family: $main-font;
  font-weight: $main-font-weight-400;
  font-size: 14px;
  color: #fff;
}

.mat-badge-content {
  @include text-8($white);
  background: $blueDark;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: 3px;
  left: auto;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: 4px;
}

.cdk-overlay-container {
  z-index: 2222; // !!! чтобы попап окна были поверх синей полоски меню
}

// эта часть была добавлена, когда в dropctrl существуют вложенные элементы
// используется во всплывающем окне new announcement
.cdk-overlay-container.has-children {
  & .forOverlayPanelDropFormCtrl {
    border-radius: $border-radius-square;
    border: 1px solid $grey_2;
    width: 538px !important;
    overflow: visible;

    & [role='listbox'] {
      overflow: visible;
      overflow-x: visible !important;

      & mat-optgroup {
        background-color: red;
        overflow-x: visible;

        & .mat-mdc-optgroup-label {
          overflow: visible;
        }

        & .mdc-list-item__primary-text {
          display: flex;
          justify-content: space-between;
        }

        & .group-options {
          display: none;
          position: absolute;
          top: 0;
          right: -200px;
          border-radius: 8px;
          border: 1px solid $grey_2;
          width: 200px;

          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
          background-color: var(--mdc-theme-surface, #fff);
          color: var(--mdc-theme-on-surface, #000);

          &--isVisible {
            display: block;
          }
        }
      }
    }
  }
}

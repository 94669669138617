app-dateRange.drop-box-14-600 {
  font-size: 14px;
  font-weight: 600;
  .o-drop-down--filled {
    font-weight: 600;
    font-size: 14px;
  }
  .o-drop-down--empty {
    font-size: inherit;
  }

  .o-drop-down--active {
    font-size: inherit;
  }

  .o-drop-down--disabled {
    font-size: inherit;
    cursor: default !important;
  }

  .o-drop-down--error {
    font-size: inherit;
  }
}

// === Fonts ==============
$main-font: 'Gilroy', 'Helvetica Neue Bold', Helvetica, Arial, sans-serif;

$main-font-weight-400: 400;
$main-font-weight-600: 600;
$main-font-weight-700: 700;

@font-face {
  font-family: 'Gilroy';
  font-weight: $main-font-weight-400;
  font-style: normal;
  src: local('Gilroy');
  src: url('../assets/fonts/Radomir Tinkov - Gilroy-Regular.otf'); // https://d2t8ebd4zpmh57.cloudfront.net/7df585ba-f9b4-447b-8542-a0ed657c939b.otf
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  font-weight: $main-font-weight-600;
  font-style: normal;
  src: local('Gilroy-semibold');
  src: url('../assets/fonts/GilroySemiBold/font.woff'); // https://d2t8ebd4zpmh57.cloudfront.net/acba080f-30ec-4126-a1dd-b58d55cdce51.woff
  src: url('../assets/fonts/GilroySemiBold/font.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  font-weight: $main-font-weight-700;
  font-style: normal;
  src: local('Gilroy-bold');
  src: url('../assets/fonts/GilroyBold/font.woff'); // https://d2t8ebd4zpmh57.cloudfront.net/d0115de1-4fd9-4a0a-83c1-f63fdf431c5c.woff
  font-display: swap;
}

// isBigDesktop$ = new BehaviorSubject<boolean>(true); // >1339
// isDesktop$ = new BehaviorSubject<boolean>(true);    // 1024-1339
// isTablet$ = new BehaviorSubject<boolean>(true);     // 720-1024 || 580-1024
// isMobile$ = new BehaviorSubject<boolean>(false);    // <720 || <580

$size-to-switch-to-the-mobile-version: 1024px;
$mobile-screen: '(max-width: 579px)';
$ipad-tablet: '(min-width: 580px) and (max-width: 1024px)';
$small-web-screens: '(min-width: 1025px) and (max-width: 1339px)';
$big-web-screens: '(min-width: 1340px) ';

//=== MENU ===============================
$widthMenuMobile: 311px;

$widthContent: 1440px;

$width-popup-desktop: 400px;
$width-popup-mobile: 344px;

$heightHeaderNavigation: 72px;
$paddingLeftHeaderNavigation: 70px;
$paddingPageLeftRight_forMobile: 16px;
$marginTopPageFromMenu_forDesktop: 20.5px; // отступ для всех страниц от верхнего меню
$marginTopPageFromMenu_forMobile: 32px; // отступ для всех страниц от верхнего меню

$widthPage: 1300px;

// === Colors => если здесь изменить цвет, то не забыть поменять в объекте colorObj src/app/shared/classes/CSS.ts ===================
$white: #ffffff;
$blueDark: #07254a;
$blueDarkLight: #344054;
$greenLight: #a9cf4a; // нету в фигме
$greenLight2: #def9e5; // нету в таблице
$green: #12b76a;
$newGreen: #64e986;
$yellow: #f3e351;
$red: #f12b2c;
$grey_1: #98a2b3;
$grey_2: #d0d5dd;
$grey_3: #eaecf0;
$grey_3_5: #e7ecf1;
$grey_4: #f6f7f8;
$grey_5: #FAFAFA;
$success-50: #ecfdf3;
$success-700: #027a48;
$gray-100: #f2f4f7;
$table-border-color: #d0d5dd;

$colorHover: rgba(100, 233, 134, 0.1);

$bordColor: #dfe0eb;

// высота для кнопок, инпутов
$height-btnInput: 44px;
// $height-btnInputMobile: 36px;

// === PADDING ========================
$padding-game-desktop: 24px;
$padding-game-mobile: 16px;
$paddingInput: 0 14px;

$border-radius-circle: 24px;
$border-radius-square: 8px;

$enlargeSize: 20px;

$minWidthSearch: 192px;
$bcg-input: #fcfdfe;
$paddingForDrop: 16px;
$colorPlaceholder: $grey_1; // --empty

//=== INPUT ===========================
//$fontSizeInput

// === BTN =========================
$btnFontSizeDesktop: 16px;
$btnFontSizeMobile: 14px;
$btnPaddingDesktop: 0 16px;
$btnPaddingMobile: 0 14px;

// === width Wrapper Competition Window
$widthWrapperCompetitionWindow: 817px;

//=== for contact component ==============================
$widthForContactCol_1: 280px;
$widthForContactCol_2: 512px;

//=== for announcements popup ==============================
$height-370: 37dvh;
$height-450: 45dvh;
$height-470: 47dvh;
$height-550: 55dvh;
$height-570: 57dvh;

select-timezone.select-timezone-14-400 {
  mat-select {
    .mat-mdc-select-value,
    .mat-mdc-select-placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

select-timezone.select-timezone-regular {
  mat-select {
    .mat-mdc-select-value,
    .mat-mdc-select-placeholder {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

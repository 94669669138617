@import 'variables';
@import 'mixins';

// old
.select-normal-16-400 {
  mat-select .mat-mdc-select-value {
    @media (max-width: $size-to-switch-to-the-mobile-version) {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

mat-select .mat-mdc-select-value {
  font-family: $main-font;
  font-weight: $main-font-weight-600;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: $blueDark;
  // @include textDropdown($blueDark);
  // color: inherit !important;
}

.mat-mdc-select-placeholder {
  @include textDropdown($colorPlaceholder);
}
